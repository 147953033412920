import { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import ChangeUserPasswordForm from './ChangeUserPasswordForm';

const ChangeUserPassword = (props) => {
  const [open, setOpen] = useState(false);

  const userId = props.user?.id;

  return (
    <Row>
      <Col>
        <Row>
          <Col>
            <Button
              variant='outline-secondary'
              onClick={() => {
                if (!open) setOpen(true);
                else setOpen(false);
              }}
            >
              {open ? 'Cancel' : 'Change Password'}
            </Button>
          </Col>
        </Row>
        {open ? (
          <Row className='mt-2'>
            <Col>
              <ChangeUserPasswordForm
                userId={userId}
                onSubmitSuccess={() => setOpen(false)}
              />
            </Col>
          </Row>
        ) : (
          <></>
        )}
      </Col>
    </Row>
  );
};

export default ChangeUserPassword;

