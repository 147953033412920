import { Formik } from 'formik';
import { useContext, useState } from 'react';
import { Alert, Button, Form, Spinner } from 'react-bootstrap';
import { storesContext } from '../../../stores/storesContext';
import { successToast } from '../../helpers/toasts/ToastUtils';

const ChangeUserPasswordForm = (props) => {
  const userStore = useContext(storesContext);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  return (
    <Formik
      initialValues={{
        newpassword: '',
        newpassword_confirmation: ''
      }}
      validate={(values) => {
        const errors = {};
        if (!values.newpassword) {
          errors.newpassword = 'Required';
        } else if (values.newpassword === values.password) {
          errors.newpassword = 'Please enter a new password';
        } else if (values.newpassword !== values.newpassword_confirmation) {
          errors.newpassword_confirmation = 'Please confirm your new password';
        }
        return errors;
      }}
      onSubmit={async (values, actions) => {
        try {
          setLoading(true);
          const response = await fetch(
            process.env.REACT_APP_AUTH_API_URL +
              'api/admin/fruition/change-password/' +
              props.userId,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + userStore.bearerToken,
                service: 'fruition'
              },
              body: JSON.stringify(values)
            }
          );

          if (response.ok) {
            successToast('Password changed successfully.');
            props.onSubmitSuccess();
          } else if (response.status === 400) {
            const data = await response.json();
            setMessage({
              variant: 'danger',
              message: data.message
            });
          } else if (response.status === 401) {
            userStore.refresh();
          } else if (response.status === 422) {
            const data = await response.json();
            if (data.errors) {
              setMessage({
                variant: 'danger',
                message: data.errors.password[0]
              });
            }
          } else {
            throw Error(response.statusText);
          }
        } catch (err) {
          if (process.env.NODE_ENV === 'development') console.error(err);
          setMessage({
            variant: 'danger',
            message:
              'Sorry something went wrong, please try again or contact support.'
          });
        } finally {
          actions.setSubmitting(false);
          setLoading(false);
        }
      }}
    >
      {(fProps) => (
        <Form noValidate onSubmit={fProps.handleSubmit}>
          <Form.Group controlId='newpassword'>
            <Form.Label>New Password</Form.Label>
            <Form.Control
              type='password'
              onChange={fProps.handleChange}
              onBlur={fProps.handleBlur}
              value={fProps.values.newpassword}
              placeholder='Enter your new password'
              isInvalid={fProps.errors.newpassword}
            />
            <Form.Control.Feedback type='invalid'>
              {fProps.errors.newpassword}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId='newpassword_confirmation'>
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type='password'
              onChange={fProps.handleChange}
              onBlur={fProps.handleBlur}
              value={fProps.values.newpassword_confirmation}
              placeholder='Confirm your new password'
              isInvalid={fProps.errors.newpassword_confirmation}
            />
            <Form.Control.Feedback type='invalid'>
              {fProps.errors.newpassword_confirmation}
            </Form.Control.Feedback>
          </Form.Group>
          {message ? (
            <Alert
              variant={message.variant}
              onClose={() => {
                setMessage(null);
              }}
              dismissible
            >
              {message.message}
            </Alert>
          ) : null}
          <Button variant='primary' type='submit'>
            {loading ? (
              <Spinner className='mx-2' animation='border' size='sm' />
            ) : (
              'Submit'
            )}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default ChangeUserPasswordForm;

