import { Formik } from 'formik';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { storesContext } from '../../stores/storesContext';
import LoadingOverlay from '../LoadingOverlay';
import PageTitle from '../helpers/PageTitle';
import { validateEmailAddress } from '../helpers/Validation';
import { errorToast } from '../helpers/toasts/ToastUtils';
import User from './User/User';
import UsersTable from './UsersTable';

function UserManager() {
  const history = useHistory();
  const { id } = useParams();
  const userStore = useContext(storesContext);

  const [loading, setLoading] = useState(false);
  const [loadingRoles, setLoadingRoles] = useState(true);
  const [roles, setRoles] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [users, setUsers] = useState([]);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  /**
   * Load all roles and users whenever the user store is updated.
   */
  useEffect(() => {
    // Roles
    setLoadingRoles(true);
    fetch(process.env.REACT_APP_AUTH_API_URL + 'api/admin/fruition/roles', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + userStore.bearerToken,
        service: 'fruition'
      }
    })
      .then((res) => {
        if (res.ok) return res.json();
        else if (res.status === 401) userStore.refresh();
        else throw Error(res.statusText);
      })
      .then((data) => {
        if (data) {
          setRoles(data);
        }
      })
      .catch((err) => {
        if (process.env.NODE_ENV === 'development') console.error(err);
      })
      .finally(() => {
        setLoadingRoles(false);
      });

    // Users
    setLoadingUsers(true);
    fetch(process.env.REACT_APP_AUTH_API_URL + 'api/users', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + userStore.bearerToken,
        service: 'fruition'
      }
    })
      .then((res) => {
        if (res.ok) return res.json();
        else if (res.status === 401) userStore.refresh();
        else throw Error(res.statusText);
      })
      .then((data) => {
        if (data) {
          setUsers(data);
        }
      })
      .catch((err) => {
        if (process.env.NODE_ENV === 'development') console.error(err);
      })
      .finally(() => {
        setLoadingUsers(false);
      });
  }, [userStore]);

  /**
   * Adds a new user object into the users list and then hides the
   * add user modal.
   *
   * @param {object} user
   */
  const addUser = (user) => {
    setUsers([...users, user]);
    setShowAddUserModal(false);
  };

  /**
   * If the user id exists in the users list then update that user.
   *
   * @param {object} user
   */
  const updateUser = (user) => {
    const index = users.findIndex((element) => element.id === user.id);
    if (index > -1) {
      const newUsers = [...users];
      newUsers[index] = user;
      setUsers(newUsers);
    }
  };

  /**
   * If an integer is supplied then change the URL to show that resource,
   * otherwise change the URL to show the list of all users.
   *
   * @param {int|null} newId
   */
  const changeSelectedUser = (newId) => {
    if (Number.isInteger(newId)) history.push('/management/users/' + newId);
    else history.push('/management/users');
  };

  // Loading data
  if (loading || loadingRoles || loadingUsers) {
    return <LoadingOverlay hideOpacity />;
  } else {
    // Check if a valid user is selected
    const selectedUser =
      users[users.findIndex((user) => user.id === parseInt(id))];

    // A valid user was selected
    if (selectedUser) {
      return (
        <div id='page-container'>
          <User
            authApiUrl={process.env.REACT_APP_AUTH_API_URL}
            bearerToken={userStore.bearerToken}
            refreshBearerToken={userStore.refresh}
            user={selectedUser}
            roles={roles}
            update={updateUser}
            close={() => changeSelectedUser(null)}
          />
        </div>
      );
    }

    // No valid user was selected
    else {
      return (
        <div id='page-container'>
          <Row>
            <Col>
              <PageTitle title={'Users'} />
            </Col>
          </Row>
          <Button
            variant='outline-success'
            className='mb-2'
            onClick={() => setShowAddUserModal(true)}
          >
            Add User
          </Button>
          <UsersTable
            data={users}
            changeSelectedUser={changeSelectedUser}
            setLoading={setLoading}
            updateUser={updateUser}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />

          {/* Add User Modal */}
          <Modal
            show={showAddUserModal}
            onHide={() => setShowAddUserModal(false)}
            backdrop='static'
          >
            <Modal.Header closeButton>
              <Modal.Title>Add User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Formik
                initialValues={{
                  roles: roles,
                  name: '',
                  email: '',
                  password: '',
                  role_id: ''
                }}
                validate={(values, props) => {
                  const errors = {};

                  if (!values.name) {
                    errors.name = 'Required';
                  }

                  if (!values.email) {
                    errors.email = 'Required';
                  } else if (!validateEmailAddress(values.email)) {
                    errors.email = 'Invalid email address';
                  }

                  if (!values.password) {
                    errors.password = 'Required';
                  }

                  if (!values.role_id) {
                    errors.role_id = 'Required';
                  } else if (
                    !values.roles.some((role) => role.id === values.role_id)
                  ) {
                    errors.role_id = 'Invalid role';
                  }

                  return errors;
                }}
                onSubmit={(values, actions) => {
                  fetch(
                    process.env.REACT_APP_AUTH_API_URL +
                      'api/admin/fruition/user',
                    {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        Authorization: 'Bearer ' + userStore.bearerToken,
                        service: 'fruition'
                      },
                      body: JSON.stringify({
                        name: values.name,
                        email: values.email,
                        password: values.password,
                        role_id: values.role_id
                      })
                    }
                  )
                    .then((res) => {
                      if (res.ok) return res.json();
                      else if (res.status === 401) userStore.refresh();
                      else if (res.status === 422)
                        res.json().then((j) => errorToast(j.errors.email[0]));
                      else throw Error(res.statusText);
                    })
                    .then((data) => {
                      if (data) {
                        addUser(data);
                      }
                    })
                    .catch((err) => {
                      if (process.env.NODE_ENV === 'development')
                        console.error(err);
                    })
                    .finally(() => {
                      actions.setSubmitting(false);
                    });
                }}
              >
                {(props) => (
                  <Form onSubmit={props.handleSubmit}>
                    <Form.Group controlId='name'>
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type='string'
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.name}
                        placeholder='Name'
                        isInvalid={props.errors.name}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {props.errors.name}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId='email'>
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        type='email'
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.email}
                        placeholder='Email Address'
                        isInvalid={props.errors.email}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {props.errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId='password'>
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type='password'
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.password}
                        placeholder='Password'
                        isInvalid={props.errors.password}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {props.errors.password}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId='role_id'>
                      <Form.Label>Role</Form.Label>
                      <Select
                        onChange={(option) =>
                          props.setFieldValue('role_id', option.value)
                        }
                        options={props.values.roles.map((role) => {
                          return {
                            value: role.id,
                            label: role.name
                          };
                        })}
                      />
                      {props.errors.role_id ? (
                        <div className='text-danger'>
                          {props.errors.role_id}
                        </div>
                      ) : null}
                    </Form.Group>
                    <Button type='submit' variant='primary'>
                      Submit
                    </Button>
                    {props.isSubmitting ? <LoadingOverlay /> : null}
                  </Form>
                )}
              </Formik>
            </Modal.Body>
          </Modal>
        </div>
      );
    }
  }
}

export default observer(UserManager);

